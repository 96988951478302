import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import {
  toggleRowSelection,
  setFilter,
  clearSelection,
  selectAllRows,
  setColumnFilter,
  setSortConfig,
  setFilterDropdown,
} from '../store/userTableSlice';
import { tableColumns } from '../tableConfig';
import {  InfraUser } from '../types';
import { FaFilter } from 'react-icons/fa';
import translations from '../locales/translations'; // Import translations
import axios from 'axios'; // For API requests
import { refreshInfraUsers } from '../services/api';
import { useLocation } from 'react-router-dom';
import { Modal, Button, Input } from 'antd'; // Import Ant Design components

import * as XLSX from 'xlsx'; // Import XLSX

interface ExtendRequest {
  id: string;
  UserId: string;
  ServerInfo: string;
  NumericId: number;
  ExtensionDate: string;
  Remarks: string;
}

const UserTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [operationType, setOperationType] = useState<string | null>(null);
    const [remarks, setRemarks] = useState<string>('');

    // Function to show the modal
    const showModal = (type: string) => {
        console.log("Show model clicked for operation:", type)
        setOperationType(type);
        setIsModalVisible(true);
    };

    const operationTitles: { [key: string]: string } = {
        disable: 'Disable Login',
        enable: 'Enable Login',
        extend: 'Extend Account Expiry',
        restartvnc: 'Restart VNC',
        refreshinfrausers: 'Refresh Infra Users',
        // Add more operations as needed
    };

    // Function to handle modal OK button
    const handleOk = async () => {
        console.log('OK button clicked');
        setIsModalVisible(false);
        if (operationType === 'disable') {
            await handleDisableLogin(remarks);
        } else if (operationType === 'enable') {
            await handleEnableLogin(remarks);
        } else if (operationType === 'restartvnc') {
            await handleRestartVnc(remarks);
        } else if (operationType === 'extend') {
            await handleExtendAccountExpiry(remarks);
        } else if (operationType === 'refreshinfrausers') {
            await handleRefreshInfraUsers(remarks);
        }
        setRemarks('');
    };

    // Function to handle modal Cancel button
    const handleCancel = () => {
        setIsModalVisible(false);
        setRemarks('');
    };

    // Update the button click handlers to show the modal
    const handleDisableLoginClick = () => showModal('disable');
    const handleEnableLoginClick = () => showModal('enable');
    const handleRestartVncClick = () => showModal('restartvnc');
    const handleExtendAccountExpiryClick = () => showModal('extend');
    const handleRefreshInfraUsersClick = () => showModal('refreshinfrausers');

  const location = useLocation();
  const { selectedRows, filter, columnFilters, sortConfig, filterDropdown } = useSelector((state: RootState) => state.userTable);

  const [users, setUsers] = useState<InfraUser[]>([]); // Local state for users data
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const [calendarDate, setCalendarDate] = useState<string>(''); // Calendar date as a string

  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [statusColor, setStatusColor] = useState<'blue' | 'red' | 'orange' | null>(null);
  const [operationResults, setOperationResults] = useState<string | null>(null);

  const [fetches, setFetches] = useState<any[]>([]); // Array for dropdown options
  const [selectedFetchId, setSelectedFetchId] = useState<string | null>(null); // Selected dropdown value

  // Add new state for the checkbox
//   const [fetchExpired, setFetchExpired] = useState<boolean>(new URLSearchParams(location.search).get('expired') === 'yes');
//   const [filterOption, setFilterOption] = useState("all");

    const queryParams = new URLSearchParams(location.search);

    // Initialize filter option based on URL query parameters
    const initialFilter = queryParams.get('expired') === 'yes' 
        ? "expired"
        : queryParams.get('active') === 'yes'
        ? "active"
        : queryParams.get('expirywithin') === '30'
        ? "expiring"
        : "all";
    const [filterOption, setFilterOption] = useState<string>(initialFilter);
    useEffect(() => {
        fetchData(null); // Fetch data when the component mounts
    }, [filterOption]);

  // Function to handle clearing the selectedRows
  const handleClearSelection = () => {
    // Dispatch the clearSelection action to reset selectedRows
    dispatch(clearSelection());
  };

  // Handle calendar change event with proper typing
  const handleCalendarChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCalendarDate(e.target.value);
  };

  // Check if the selected date is in the future
  const isFutureDate = (dateString: string): boolean => {
    const today = new Date();
    const selectedDate = new Date(dateString);
    return selectedDate > today;
  };

  // Function to export table data to Excel
  const handleExportToExcel = () => {
    // Prepare the data
    const data = filteredData.map((user) => {
      const exportData: { [key: string]: any } = {};
      tableColumns.forEach((column) => {
        exportData[column.header] = user[column.key as keyof InfraUser];
      });
      return exportData;
    });

    // Create a new workbook and add the data
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'InfraUsers');

    // Export to Excel file
    XLSX.writeFile(workbook, 'UserTable.xlsx');
  };

  // Function to fetch data (either from API or local file)
  const fetchData = async (fetchid: string | null) => {
    try {
      setLoading(true);
      setError(null);

        // Initialize query
        let queryParams = [];

        // Add filter options to query parameters
        if (filterOption === "active") {
            queryParams.push("active=yes");
        } else if (filterOption === "expired") {
            queryParams.push("expired=yes");
        } else if (filterOption === "expiring") {
            queryParams.push("expirywithin=30");
        }

        // Add selected fetch ID to query parameters
        if (fetchid) {
            queryParams.push(`fetchid=${fetchid}`);
        } else if (selectedFetchId) {
            queryParams.push(`fetchid=${selectedFetchId}`);
        }

        // Combine query parameters
        const query = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
        console.log(`Fetch id being requested(${selectedFetchId}) with query: ${query}`);


      // Replace with your API URL when backend is active
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/infrauser/getall${query}`

      // Check if the API is available, else fallback to local file
      const response = await axios.get(apiUrl, {
                // baseURL: process.env.REACT_APP_BACKEND_URL,
                withCredentials: true,
            },
        );
      setUsers(response.data);
    } catch (apiError) {
      console.warn('API not available, falling back to local data');
      // Fetch from local file as fallback
      const localData = (await import('../data.json')).default;
      console.log("Local populated Data:", localData)
      setUsers(localData);
    } finally {
      setLoading(false);
    }
  };

    const fetchDropdownData = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/infrauser/getfetches`, {
        withCredentials: true,
        });
        setFetches(response.data); // Assuming response.data is an array of objects
        // console.log("Fetched dropdown data:", response.data);
    } catch (error) {
        console.error("Failed to fetch dropdown data:", error);
    }
    };

    useEffect(() => {
        fetchDropdownData(); // Fetch data when the component mounts
    }, []);

    const handleFetchSelectionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        console.log('Selected fetch ID:', e.target.value);
        setSelectedFetchId(e.target.value); // Update selected fetch ID
        fetchData(e.target.value); // Fetch data with the updated fetch ID
    };

  const handleCheckboxChange = (sid: string) => {
    dispatch(toggleRowSelection(sid));
  };

  const handleDisableLogin = async (remarks: string) => {
    console.log('Disable Login for: ', selectedRows, "\nRemarks: ", remarks);
    updateStatusMessageToOperationInProgress('Disable Login operation in progress.... Wait...');

    if (selectedRows.length === 0) {
        console.warn("No records selected to disable");
        return;
    }


    // Prepare the data to be sent to the backend
    const disableRequests = selectedRows.map((sid) => {
        const user = users.find((u) => u.sid === sid);
        if (user) {
            return {
                id: user.sid,               // Backend expects an 'id'
                UserId: user.UserId,         // UserId to cross-validate
                ServerInfo: user.ServerInfo, // Server Info to cross-validate
                NumericId: user.NumericId    // NumericId to cross-validate
            };
        }
        return null;
    }).filter(Boolean);

    try {
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/infrauser/disable`;

        // Make an API request to disable selected users
        const response = await axios.post(apiUrl, disableRequests, {
            withCredentials: true, // To include cookies if needed
            });

        if (response.status === 200) {
            console.log("Users disabled successfully:", response.data);
            // await fetchData(selectedFetchId); // Refresh user list after successful operation
            updateStatusMessageToInfo('Disable Login operation completed... Check results');
        } else {
            console.error("Error in disabling users request execution: ", response.data);
            updateStatusMessageToError('Disable Login operation FAILED!!! check resuls');

            setStatusMessage('Error in disabling users request execution');
            setStatusColor('red'); // Red for failure
        }
        if (isResponseData(response.data)) {
            // The response is of type ResponseData
            const formattedResults = formatOperationResults(response.data);
            setOperationResults(formattedResults);
        } else {
            // Handle unexpected response structure
            console.error('Unexpected response structure', response.data);
            setOperationResults('Unexpected response structure received.');
        }
    } catch (error) {
        console.error("Failed to disable users:", error);
    }
    handleClearSelection()
  };

  const handleEnableLogin = async (remarks: string) => {
    console.log('Enable Login for: ', selectedRows);
    updateStatusMessageToOperationInProgress('Enable User Operation started...Wait');

    if (selectedRows.length === 0) {
        console.warn("No records selected to enable");
        return;
    }


    // Prepare the data to be sent to the backend
    const enableRequests = selectedRows.map((sid) => {
        const user = users.find((u) => u.sid === sid);
        if (user) {
            return {
                id: user.sid,               // Backend expects an 'id'
                UserId: user.UserId,         // UserId to cross-validate
                ServerInfo: user.ServerInfo, // Server Info to cross-validate
                NumericId: user.NumericId    // NumericId to cross-validate
            };
        }
        return null;
    }).filter(Boolean);

    try {
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/infrauser/enable`;

        // Make an API request to disable selected users
        const response = await axios.post(apiUrl, enableRequests, {
            withCredentials: true, // To include cookies if needed
            });

        if (response.status === 200) {
            console.log("Users enabled successfully:", response.data);
            // await fetchData(selectedFetchId); // Refresh user list after successful operation
            updateStatusMessageToInfo('Enable User Operation completed. Check resuls');
        } else {
            console.error("Error in enabling users:", response.data);
            updateStatusMessageToError('Enable User Operation failed. Check resuls');
        }
        if (isResponseData(response.data)) {
            // The response is of type ResponseData
            const formattedResults = formatOperationResults(response.data);
            setOperationResults(formattedResults);
        } else {
            // Handle unexpected response structure
            console.error('Unexpected response structure', response.data);
            setOperationResults('Unexpected response structure received.');
        }

    } catch (error) {
        console.error("Failed to enable users:", error);
    }
    handleClearSelection()

  };

  const handleRestartVnc = async (remarks: string) => {
    console.log('VNC Restart for: ', selectedRows, "\nRemarks: ", remarks);
    updateStatusMessageToOperationInProgress('VNC Restart operation in progress.... Wait...');

    if (selectedRows.length === 0) {
        console.warn("No records selected for vnc restart");
        return;
    }


    // Prepare the data to be sent to the backend
    const restartVncRequests = selectedRows.map((sid) => {
        const user = users.find((u) => u.sid === sid);
        if (user) {
            return {
                id: user.sid,               // Backend expects an 'id'
                UserId: user.UserId,         // UserId to cross-validate
                ServerInfo: user.ServerInfo, // Server Info to cross-validate
                VNCPorts: user.VNCPorts,    // VNCPorts for restart (only first to be restarted)
                Enabled: user.Enabled,       // Enabled status to cross-validate
                NumericId: user.NumericId,    // NumericId to cross-validate
                Remarks: remarks,            // Remarks entered in the modal
            };
        }
        return null;
    }).filter(Boolean);

    try {
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/infrauser/restartvnc`;

        // Make an API request to disable selected users
        const response = await axios.post(apiUrl, restartVncRequests, {
            withCredentials: true, // To include cookies if needed
            });

        if (response.status === 200) {
            console.log("Users disabled successfully:", response.data);
            // await fetchData(selectedFetchId); // Refresh user list after successful operation
            updateStatusMessageToInfo('Restart VNC operation completed... Check results');
        } else {
            console.error("Error in restrart VNC request execution: ", response.data);
            updateStatusMessageToError('Restrart VNC operation FAILED!!! check resuls');

            setStatusMessage('Error in restrart VNC request execution');
            setStatusColor('red'); // Red for failure
        }
        if (isResponseData(response.data)) {
            // The response is of type ResponseData
            const formattedResults = formatOperationResults(response.data);
            setOperationResults(formattedResults);
        } else {
            // Handle unexpected response structure
            console.error('Unexpected response structure', response.data);
            setOperationResults('Unexpected response structure received.');
        }
    } catch (error) {
        console.error("Failed to handle restrart VNC requests :", error);
    }
    handleClearSelection()
  };

const handleExtendAccountExpiry = async (remarks: string): Promise<void> => {
    console.log('Extend Login for: ', selectedRows, "\nRemarks: ", remarks);
    updateStatusMessageToOperationInProgress('Extend Account Expiry operation in progress.... Wait...');

    if (selectedRows.length === 0) {
        console.warn("No records selected to extend expiry.");
        return;
    }

    // Prepare the data to be sent to the backend with the selected ExtensionDate from the calendar
    const extendRequests: ExtendRequest[] = selectedRows.map((sid) => {
        const user = users.find((u) => u.sid === sid);
        if (user) {
            return {
                id: user.sid,               // Backend expects an 'id'
                UserId: user.UserId,         // UserId to cross-validate
                ServerInfo: user.ServerInfo, // Server Info to cross-validate
                NumericId: user.NumericId,   // NumericId to cross-validate
                ExtensionDate: calendarDate, // Date selected from the calendar
                Remarks: remarks,            // Remarks entered in the modal
            };
        }
        return null;
    }).filter((request): request is ExtendRequest => request !== null); // Type guarding

    try {
        const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/infrauser/extendacctexpiry`;

        // Make an API request to extend account expiry for selected users
        const response = await axios.post(apiUrl, extendRequests, {
            withCredentials: true, // To include cookies if needed
        });

        if (response.status === 200) {
            console.log("Expiry extended successfully:", response.data);
            // await fetchData(selectedFetchId); // Refresh user list after successful operation
            updateStatusMessageToInfo('Acct Expiry extennsion completed. Check resuls'); // Blue for success
        } else {
            console.error("Error in extending expiry:", response.data);
            updateStatusMessageToError('Error in extending expiry... check results'); // Red for failure
       }
        if (isResponseData(response.data)) {
            // The response is of type ResponseData
            const formattedResults = formatOperationResults(response.data);
            setOperationResults(formattedResults);
        } else {
            // Handle unexpected response structure
            console.error('Unexpected response structure', response.data);
            setOperationResults('Unexpected response structure received.');
        }


    } catch (error) {
        console.error("Failed to extend expiry for users:", error);
        updateStatusMessageToError('Error in extending expiry'); // Red for failure
    }
    handleClearSelection()
  };

  const handleRefreshInfraUsers = async (remarks: string) => {
    console.log('Handle Refresh Infra Users');
    updateStatusMessageToOperationInProgress('Refresh InfraUsers Operation in progress.... Wait...');
    try {
        await refreshInfraUsers(remarks);
        setSelectedFetchId(null);
        await fetchData(null);
    	updateStatusMessageToInfo('Refresh InfraUser completed! Check resuls');
        fetchDropdownData(); // Fetch dropdown data after successful operation
    } catch(error) {
        const err = error as Error ;
        console.log("Failure in Refreshing Users");
        updateStatusMessageToError('Refresh InfraUser operation FAILED! check results');
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>, columnKey: keyof InfraUser) => {
    dispatch(setColumnFilter({ key: columnKey, value: e.target.value }));
  };

  const handleSort = (columnKey: keyof InfraUser) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    dispatch(setSortConfig({ key: columnKey, direction }));
  };

  const handleFilterDropdownToggle = (columnKey: string) => {
    dispatch(setFilterDropdown(filterDropdown === columnKey ? null : columnKey));
  };

  const updateStatusMessageToError = async (message:string) => {
            setStatusMessage(message);
            setStatusColor('red');
    };
  const updateStatusMessageToInfo = async (message:string) => {
            setStatusMessage(message);
            setStatusColor('blue');
    };
  const updateStatusMessageToOperationInProgress = async (message:string) => {
            setStatusMessage(message);
            setStatusColor('orange');
    };

interface Result {
    id: string;
    status: string;
}

interface ResponseData {
    message: string;
    results: Result[];
}

    // Type guard to validate ResponseData structure
    const isResponseData = (data: any): data is ResponseData => {
        return (
            typeof data === 'object' &&
            data !== null &&
            typeof data.message === 'string' &&
            Array.isArray(data.results) &&
            data.results.every((result: any): result is Result =>
                typeof result.id === 'string' &&
                typeof result.status === 'string'
            )
        );
    };

    const formatOperationResults = (data: ResponseData): string => {
        const { message, results } = data;
        let formattedResults = `${message}\n\n`;

        results.forEach(result => {
            formattedResults += `ID: ${result.id}, Status: ${result.status}\n`;
        });

        // Replace <br /> with newlines
        formattedResults = formattedResults.replace(/<br\s*\/?>/gi, '\n');

        return formattedResults;
    };

  const sortedData = React.useMemo(() => {
    if (sortConfig) {
      return [...users].sort((a, b) => {
        const aValue = a[sortConfig.key] ?? '';
        const bValue = b[sortConfig.key] ?? '';

        if (aValue < bValue) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return users;
  }, [users, sortConfig]);

  const filteredData = sortedData.filter((user) =>
    tableColumns.every((column) =>
      columnFilters[column.key as keyof InfraUser]
        ? String(user[column.key as keyof InfraUser]).toLowerCase().includes(columnFilters[column.key as keyof InfraUser]!.toLowerCase())
        : true
    )
  );

const dateFields = ["SetupDate", "AccountExpiry", "LastUpdated", "LastLoginTime"];

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error}</p>;

  return (
    <div className="px-4 py-6 w-full" style={{ fontFamily: "'Roboto', sans-serif", backgroundColor: "#f4f6f8" }}>
      <div className="flex justify-between items-center mb-4">

      {/* <button onClick={handleClearSelection}>
        Clear Selection
      </button> */}

        <div className="space-x-4 flex items-center">
          <button
            className={`px-4 py-2 bg-red-500 text-white rounded-lg shadow-sm hover:bg-red-600 disabled:bg-red-300 disabled:cursor-not-allowed transition ${
              selectedRows.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleDisableLoginClick}
            disabled={selectedRows.length === 0}
          >
            {translations.en.disableLogin}
          </button>
          <button
            className={`px-4 py-2 bg-green-500 text-white rounded-lg shadow-sm hover:bg-green-600 disabled:bg-green-300 disabled:cursor-not-allowed transition ${
              selectedRows.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleEnableLoginClick}
            disabled={selectedRows.length === 0}
          >
            {translations.en.enableLogin}
          </button>
          <button
            className={`px-4 py-2 bg-green-500 text-white rounded-lg shadow-sm hover:bg-green-600 disabled:bg-green-300 disabled:cursor-not-allowed transition ${
              selectedRows.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleRestartVncClick}
            disabled={selectedRows.length === 0}
          >
            {translations.en.restartVNC}
          </button>


          {/* Calendar for extending expiry */}
          <input
            type="date"
            value={calendarDate}
            onChange={handleCalendarChange}
            className="border rounded-lg p-2"
          />

          {/* Extend Account Expiry Button (enabled only if date is in future) */}
          <button
            className={`px-4 py-2 bg-green-500 text-white rounded-lg shadow-sm hover:bg-green-600 disabled:bg-green-300 disabled:cursor-not-allowed transition ${
            selectedRows.length === 0 || !isFutureDate(calendarDate) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleExtendAccountExpiryClick}
            disabled={selectedRows.length === 0 || !isFutureDate(calendarDate)}
          >
            Extend Account Expiry
          </button>


          <button
            className={"px-4 py-2 bg-green-500 text-white rounded-lg shadow-sm hover:bg-green-600 disabled:bg-green-300 disabled:cursor-not-allowed transition"}
            onClick={handleRefreshInfraUsersClick}
          >
            {translations.en.refreshInfraUsers}
          </button>

        {/* Add export-to-Excel button */}
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-sm hover:bg-blue-600 transition"
          onClick={handleExportToExcel}
        >
          Export to Excel
        </button>

            {/* <label>
                <input
                    type="checkbox"
                    checked={fetchExpired}
                    onChange={() => setFetchExpired(!fetchExpired)}
                    style={{ marginRight: '8px' }} // Adds space between checkbox and text
                />
             Show Expired Only
            </label> */}

            {/* Encased box for radio buttons */}
            <div className="border p-4 rounded-lg bg-gray-100"> {/* Added border, padding, and background */}

                    <div className="flex items-center space-x-4">
                        <label className="flex items-center">
                            <input
                            type="radio"
                            value="all"
                            checked={filterOption === "all"}
                            onChange={() => setFilterOption("all")}
                            className="mr-1" // Adds margin to the right of the radio button
                            />
                            All
                        </label>
                        <label className="flex items-center">
                            <input
                            type="radio"
                            value="active"
                            checked={filterOption === "active"}
                            onChange={() => setFilterOption("active")}
                            className="mr-1"
                            />
                            Active Users
                        </label>
                        <label className="flex items-center">
                            <input
                            type="radio"
                            value="expired"
                            checked={filterOption === "expired"}
                            onChange={() => setFilterOption("expired")}
                            className="mr-1"
                            />
                            Expired Users
                        </label>
                        <label className="flex items-center">
                            <input
                            type="radio"
                            value="expiring"
                            checked={filterOption === "expiring"}
                            onChange={() => setFilterOption("expiring")}
                            className="mr-1"
                            />
                            Expiring Next Month
                        </label>
                    </div>
            </div>

            <div className="mb-4">
            <label htmlFor="fetchDropdown" className="mr-2">SelectFetch:</label>
            <select
                id="fetchDropdown"
                value={selectedFetchId || ""}
                onChange={handleFetchSelectionChange}
                className="border rounded-lg p-2"
            >
                <option value="">-- Select --</option>
                {fetches
                    .filter((fetch) => fetch.fetch_tracking_NumRecs > 0) // Filter records with NumRecs > 0
                    .map((fetch) => (
                        <option key={fetch.fetch_tracking_id} value={fetch.fetch_tracking_FetchId}>
                            {`${fetch.fetch_tracking_FetchTime} [${fetch.fetch_tracking_NumRecs}] (${fetch.fetch_tracking_id})`}
                        </option>
                    ))}
            </select>
            </div>

        {statusMessage && (
            <p style={{ color: statusColor === 'blue' ? 'blue' : 'red' }}>
                {statusMessage}
            </p>
        )}
        </div>
      </div>

    <Modal
      title={operationTitles[operationType || ''] || 'Unknown Operation'} // Default to 'Unknown Operation'
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Input.TextArea
        value={remarks}
        onChange={(e) => setRemarks(e.target.value)}
        placeholder="Enter remarks"
        rows={4}
      />
    </Modal>
      
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 shadow-sm rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-3 px-4 border-b">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    dispatch(
                      e.target.checked
                        ? selectAllRows(filteredData.map((user) => user.sid))
                        : clearSelection()
                    )
                  }
                  checked={
                    selectedRows.length === filteredData.length &&
                    selectedRows.length > 0
                  }
                />
              </th>
              {tableColumns.map((column, index) => (
                <th
                  key={index}
                  className="py-3 px-4 border-b text-left font-medium text-gray-800"
                >
                  <div className="flex flex-col">
                    <div className="flex items-center cursor-pointer" onClick={() => handleSort(column.key as keyof InfraUser)}>
                      {column.header}
                      <FaFilter
                        className="ml-2 text-gray-600 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFilterDropdownToggle(column.key);
                        }}
                        style={{ width: '16px', height: '16px' }} 
                      />
                    </div>
                    {filterDropdown === column.key && (
                      <div className="mt-2">
                        <input
                          type="text"
                          placeholder={`${translations.en.filterBy} ${column.header}`}
                          className="p-1 border border-gray-300 rounded"
                          value={columnFilters[column.key as keyof InfraUser] || ''}
                          onChange={(e) => handleFilterChange(e, column.key as keyof InfraUser)}
                        />
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((user) => (
              <tr key={user.sid} className="hover:bg-gray-50">
                <td className="py-2 px-4 border-b">
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(user.sid)}
                    onChange={() => handleCheckboxChange(user.sid)}
                  />
                </td>
                {tableColumns.map((column, index) => (
                  <td key={index} className="py-2 px-4 border-b text-gray-900">

                    {user[column.key as keyof InfraUser] !== undefined && user[column.key as keyof InfraUser] !== null && user[column.key as keyof InfraUser] !== ''
                    ? typeof user[column.key as keyof InfraUser] === 'boolean'
                        ? user[column.key as keyof InfraUser]
                        ? translations.en.yes
                        : translations.en.no
                        : dateFields.includes(column.key as string) && !isNaN(Date.parse(user[column.key as keyof InfraUser] as string))
                        ? new Date(user[column.key as keyof InfraUser] as string).toLocaleString('en-IN', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            // hour: '2-digit',
                            // minute: '2-digit',
                            hour12: false,
                            }).replace(',', '') // Removes the comma from the date string
                        : user[column.key as keyof InfraUser]
                    : translations.en.empty}

                    {/* {user[column.key as keyof InfraUser] !== undefined
                      ? typeof user[column.key as keyof InfraUser] === 'boolean'
                        ? user[column.key as keyof InfraUser] ? translations.en.yes : translations.en.no
                        : user[column.key as keyof InfraUser]
                      : translations.en.nA} */}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserTable;
