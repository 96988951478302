// src/locales/translations.ts
const translations = {
    en: {
      //navbar
      userManagementApp: 'User Management App',
      home: 'Home',
      userTable: 'User Table',
      settings: 'Settings',
      uploadExcel: 'Upload Excel',

      // userTabel page
      userManagement: 'User Management',
      globalFilterPlaceholder: 'Global Filter...',
      disableLogin: 'Disable Login',
      enableLogin: 'Enable Login',
      extendLogin: 'Extend Login',
      restartVNC: 'Restart VNC',
      refreshInfraUsers: 'Refresh InfraUsers', 
      filterBy: 'Filter by',
      yes: 'Yes',
      no: 'No',
      nA: 'N/A',
      empty: "",

        // User Generation Page translations
    userGenerationPageTitle: 'User Generation Page',
    pleaseSelectFile: 'Please select a file.',
    reuploadFile: 'Reupload File',
    generateUsers: 'Generate Users',
    uploadAnotherFile: 'Upload Another File',
    status: 'Status',

    reviewingByUser: 'Reviewing by user',
    successfullyUploaded: 'Successfully uploaded',
    genOprnWIP: 'InfraUser Generation Operation in progress... Please wait.',
    genOprnCompleted: 'InfraUser Generation Operation Complete. Check results.',

    },
    // Add other languages here, if necessary
    
  };
  
  export default translations;
  